import React from 'react';

export const AppContext = React.createContext({
    isFirstFullResImageLoaded: null,
    showingLoadingIcon: null,
    isHomepageDataLoaded: null,
    showHomepage: null,
    onFirstFullResImageLoaded: null,
    setLoadingIconVisibility: null,
    onHomepageDataLoaded: null,
    timerDone: null,
    resetLoadTime: null,
    isLoggedIn: null,
    setIsLoggedIn: null,
});
