import React from "react";
import './SplashScreen.css';
// * IMPORTANT: importing with an absolute path here to limit the first js chunk size.
import { LoadingIcon2 } from 'web-store-modules/packages/web-store-ui-library/';
import { TextWithBackground } from 'web-store-modules/packages/web-store-ui-library';
import { isCurrentPathIn } from '../../../utils/History.js';
import { STATIC_ROUTES } from '../../UrlEnums.js';

const { PRIVACY_VIEW_PATH, TERMS_VIEW_PATH, ABOUT_US_VIEW_PATH } = STATIC_ROUTES

const url = new URL(window.location.href);
const skipSplash = url.searchParams.get('skipSplash');

const textWithBackgroundProps = {
    size: '18px',
    skewed: -30,
    margin: '0 0 0.7em',
    padding: '0.5em'
};

let randInt;  // Random int used to determine welcome splash screen second lines

function SplashScreen (props) {
    const isPathStatic =  isCurrentPathIn([PRIVACY_VIEW_PATH, TERMS_VIEW_PATH, ABOUT_US_VIEW_PATH]);

    if ((skipSplash && parseInt(skipSplash) === 1 || isPathStatic) ) {
        return null;
    }

    const getMessages = () => {
        const welcomeFirstLines = [
            <TextWithBackground {...textWithBackgroundProps} text="VIRTUAL STORES FOR" />,
            <TextWithBackground {...textWithBackgroundProps} text="WE BRING YOU" />,
            <TextWithBackground {...textWithBackgroundProps} text="MAKING SHOPPING" />
        ]

        const welcomeSecondLines = [
            <TextWithBackground {...textWithBackgroundProps} text="THE LATEST TRENDS" />,
            <TextWithBackground {...textWithBackgroundProps} text="TODAY'S HOTTEST BRANDS" />,
            <TextWithBackground {...textWithBackgroundProps} text="FUN AGAIN" />
        ];

        randInt = (randInt || randInt === 0) ? randInt : Math.floor(Math.random() * welcomeFirstLines.length);

        return (
                <React.Fragment>
                    {welcomeFirstLines[randInt]}
                    {welcomeSecondLines[randInt]}
                </React.Fragment>
            );
    }


    return (
        <div id='splashScreen'>
            <div id='splashScreenItemWrapper' className="flex-center">
                <img
                    id='obsessLogo'
                    src='https://s3.amazonaws.com/obsessvr-webstore-assets-public/obsess-logo-v3.png'
                />
                {getMessages()}
                <div id='splashLoadingIcon'>
                    <LoadingIcon2 />
                </div>
            </div>
        </div>
    );
}

export default SplashScreen;
