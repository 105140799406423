import { isMobileDevice } from 'web-store-modules/packages/obsess-device-detector';
const isMobile = isMobileDevice();

export const CLOSE_ICON_URL = "https://cdn.obsess-vr.com/modal-close-icon-normal-v2.png";
export const CONTACT_URL = "mailto:contact@ShopObsess.co";

export const HOME_PATH = '/';
export const ALL_STORES_VIEW_PATH = '/';
export const SAVED_PRODUCT_VIEW_PATH = '/saved-products';

export const NON_STORE_PATHS = [SAVED_PRODUCT_VIEW_PATH, HOME_PATH, ALL_STORES_VIEW_PATH];
export const HOME_SHARE_PATHS = [SAVED_PRODUCT_VIEW_PATH, HOME_PATH]; // Share link to home when on these paths

export const STATIC_ROUTES = {
    TERMS_VIEW_PATH: '/terms',
    PRIVACY_VIEW_PATH: '/privacy',
    ABOUT_US_VIEW_PATH: '/about',
    SITEMAP_VIEW_PATH: '/sitemap',
}

export const sendEmailBasedOnDevice = () => {
    if (isMobile) {
        window.location.assign(CONTACT_URL);
    } else {
        window.open(CONTACT_URL, '_blank')
    }
};