import React from 'react';
import ReactDOM from 'react-dom';

// import App from './components/App';
import { reactRoot } from './utils/ENVVariables';
import Entry from './components/Entry.jsx';
import './main.css';

window.onload = () => {
    // if ('serviceWorker' in navigator) {
    //     navigator.serviceWorker.register('/service-worker.js')
    //         .then(registration => {
    //             console.log('ServiceWorker registration successful with scope: ', registration.scope);
    //         })
    //         .catch(error => {
    //             console.error('ServiceWorker registration failed: ', error);
    //         });
    // } else {
    //     console.log('no service worker');
    // }

    ReactDOM.render(<Entry />, document.getElementById(reactRoot));
};
