import { createBrowserHistory, createHashHistory } from 'history';
import { HOME_PATH, SAVED_PRODUCT_VIEW_PATH } from '../components/UrlEnums.js'

// HASH_HISTORY is injected into .env as HASH_HISTORY=true for feature branches, see Dockerfile and .gitlab-ci.yml for details
export const history = (process.env.HASH_HISTORY === 'true') ? createHashHistory() : createBrowserHistory();

export function redirect(pathname, search = null) {
    history.replace({ pathname, search, state: { canRedirect: true } });
}

export function push(pathname) {
    history.push(pathname);
}

export function isCurrentPath(path) {
    return history.location.pathname === path;
}

export function isCurrentPathIn(paths) {
    return paths.includes(history.location.pathname);
}

export function isHomepage() {
    return isCurrentPath(HOME_PATH);
}

export function isSavedProducts() {
    return isCurrentPath(SAVED_PRODUCT_VIEW_PATH);
}

export function isCurrentPathAStore() {
    return !isCurrentPathIn(['/terms', '/privacy', '/sitemap', '/', HOME_PATH]);
}

export const pwdProtectedRoutes = ["/virtual-mall", "/jared", "/ralph-lauren", "/charlotte-tilbury", "/lee", "/nars", "/fendi", "/general-mills", " /american-girl", "/kitchenware-goods", "/coffee-n-clothes"]
