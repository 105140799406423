import React from 'react';
import Loadable from 'react-loadable';
import SplashScreen from './ui-layer/splash-screen/SplashScreen';
import AppContextComponent from "./AppContextComponent";
import { AppContext } from './AppContext';
import { history, pwdProtectedRoutes } from "../utils/History";


/**
 * We use react-loadable here to make our js bundle into smaller chunks.
 *
 * The first chunk should only contain the splash screen and the triggers for displaying the homepage.
 *
 * Splashscreen should also display for a minimum time of 2 seconds
 */
const LoadableApp = Loadable({
    loader: () => import('./App.jsx'),
    loading(props) {
        if (props.error) {
            console.error('Load App Error, Retry!', props.error);
            props.retry();
        }
        return null;
    },
    render(loaded, props) {
        const {appContext} = props;
        const Component = loaded.default;
        return <Component appContext={appContext}/>
    }
});

/**
 * This is the entry point of the app.
 *
 * This component wraps the entire functionality of the react application in the AppContext
 */
export default function Entry() {
    return (
        <AppContextComponent>
            <AppContext.Consumer>
                {appContext => (
                    <React.Fragment>
                        {(!appContext.showHomepage || !appContext.timerDone) && (appContext.isLoggedIn || !pwdProtectedRoutes.includes(history.location.pathname)) && <SplashScreen />}
                        <LoadableApp appContext={appContext}/>
                    </React.Fragment>
                )}
            </AppContext.Consumer>
        </AppContextComponent>
    )
}