import React, { Component } from 'react';
import { AppContext } from './AppContext.jsx';
import { isHomepage } from "../utils/History";
import { homepageRenderedSpeed } from "../analytics/Analytics";

const loadStartTime = new Date();
const minSplashScreenTime = 2000;

export default class AppContextComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFirstFullResImageLoaded: false,
            showingLoadingIcon: false,
            showHomepage: false,
            timerDone: false,
            isHomepageDataLoaded: false,
            isLoggedIn: false,
        };

        this.onFirstFullResImageLoaded = this.onFirstFullResImageLoaded.bind(this);
        this.setLoadingIconVisibility = this.setLoadingIconVisibility.bind(this);
        this.onHomepageDataLoaded = this.onHomepageDataLoaded.bind(this);
        this.setIsLoggedIn = this.setIsLoggedIn.bind(this);
    }

    componentDidMount() {
        const loadEndTime = new Date();
        const loadTime = loadEndTime.getTime() - loadStartTime.getTime();
        const splashScreenTimeLeft = minSplashScreenTime - loadTime;
        setTimeout(() => this.setState({ timerDone: true }), splashScreenTimeLeft);
    }

    // Using a separate showHomepage flag to determine when to render homepage to allow a little time for the
    // homepage screen to render in the background
    componentDidUpdate(_prevProps, prevState) {
        const { isFirstFullResImageLoaded, isHomepageDataLoaded } = this.state;
        if (!(prevState.isFirstFullResImageLoaded && prevState.isHomepageDataLoaded) && (isFirstFullResImageLoaded && isHomepageDataLoaded)) {
            setTimeout(() => this.setState({ showHomepage: true }), 100);
            if (isHomepage()) {
                homepageRenderedSpeed();  // Track speed of homepage rendering if directly landing on homepage. Does not include 100 ms timeout
            }
        }
    }

    /**
     * Callback for when the first full resolution image is loaded.
     * Used for show/hide splash screen and UI.
     */
    onFirstFullResImageLoaded() {
        if (this.state.isFirstFullResImageLoaded) {
            return;
        }
        this.setState({ isFirstFullResImageLoaded: true });
    }

    /**
     * Set the visibility of the loading icon on the top level.
     * @param {Boolean} show - loading icon is visible
     */
    setLoadingIconVisibility(show) {
        this.setState({ showingLoadingIcon: show });
    }
    setIsLoggedIn(val) {
        this.setState({ isLoggedIn: val });
    }

    onHomepageDataLoaded() {
        if (this.state.isHomepageDataLoaded) {
            return;
        };
        this.setState({ isHomepageDataLoaded: true });
    }


    render() {
        const { isFirstFullResImageLoaded, showingLoadingIcon, isHomepageDataLoaded, showHomepage, timerDone, isLoggedIn } = this.state;
        const appContextValue = {
            isFirstFullResImageLoaded,
            showingLoadingIcon,
            isHomepageDataLoaded,
            showHomepage,
            timerDone,
            isLoggedIn,
            onFirstFullResImageLoaded: this.onFirstFullResImageLoaded,
            setLoadingIconVisibility: this.setLoadingIconVisibility,
            onHomepageDataLoaded: this.onHomepageDataLoaded,
            setIsLoggedIn: this.setIsLoggedIn,
        };

        return <AppContext.Provider value={appContextValue}>{this.props.children}</AppContext.Provider>
    }
}