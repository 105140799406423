const reactRoot = 'obsessvr-webstore-react-embed-root';
const modalWrapper = 'obsessModalWrapper'

const mallUrl = process.env.MALL_URL;
const webStoreApiUrl = process.env.WEB_STORE_API_URL;
const webStoreS3BucketPath = process.env.WEB_STORE_BUCKET_PATH;
const userApiUrl = process.env.USER_API_URL;

module.exports = {
    webStoreApiUrl,
    userApiUrl,
    webStoreS3BucketPath,
    mallUrl,
    reactRoot,
    modalWrapper
};
