const AtLeastOnce = {
    EventCategory: 'At least once',
    Props: {
        MovedOnce: {
            EventAction: 'Moved once',
            EventLabel: 'Moved once',
            called: false
        },
        ProductHotspotOnce: {
            EventAction: 'Product hotspot once',
            EventLabel: 'Product hotspot once',
            called: false
        },
        BuyNowOnce: {
            EventAction: 'Buy now once',
            EventLabel: 'Buy now once',
            called: false
        },
        InteractedOnce: {
            EventAction: 'Interacted once',
            EventLabel: 'Interacted once',
            called: false
        }
    }
}

const Navigation = {
    EventCategory: 'Navigation',
    Props: {
        SceneLoaded: {
            EventAction: 'Scene loaded',
            EventLabel: '<Store name> - <Scene name>'
        },
        NavMarkerClicked: {
            EventAction: 'NavMarker clicked',
            EventLabel: '<Destination store name>'
        },
        UIStoreClicked: {
            EventAction: 'All stores store clicked',
            EventLabel: '<Tag> - <Store name>'
        },
        ProgressBarClicked: {
            EventAction: 'Progress bar clicked',
            EventLabel: '<Destination store name>'
        },
        ProgressMessageSeen: {
            EventAction: 'Progress message seen',
            EventLabel: '<Store name> - <Scene name>'
        },
        NavMarkerClickedAfterProgressMessageSeen: {
            EventAction: 'NavMarker clicked after progress message seen',
            EventLabel: '<Destination store name>'
        }
    }
}

const Product = {
    EventCategory: 'Product',
    Props: {
        ProductHotspotClicked: {
            EventAction: 'Product hotspot clicked',
            EventLabel: '<Store name> - <Product name> - <SKU>'
        },
        BuyNowClicked: {
            EventAction: 'Buy now clicked',
            EventLabel: '<Store name> - <Product name> - <SKU>'
        },
        SaveClicked: {
            EventAction: 'Save clicked',
            EventLabel: '<Store name> - <Product name> - <SKU>'
        },
        UnsaveClicked: {
            EventAction: 'Unsave clicked',
            EventLabel: '<Store name> - <Product name> - <SKU>'
        },
        SaveComplete: {
            EventAction: 'Save complete',
            EventLabel: '<Store name> - <Product name> - <SKU>'
        },
        ProductThumbnailZoomed: {
            EventAction: 'Product thumbnail zoomed',
            EventLabel: '<Store name> - <Product name> - <SKU>'
        },
        SaveClickedInObsess: {
            EventAction: 'Save clicked in obsess',
            EventLabel: '<Brand name> - <Product name> - <SKU>'
        },
        UnsaveClickedInObsess: {
            EventAction: 'Unsave clicked in obsess',
            EventLabel: '<Brand name> - <Product name> - <SKU>'
        },
        SaveCompleteInObsess: {
            EventAction: 'Save complete in obsess',
            EventLabel: '<Brand name> - <Product name> - <SKU>'
        },
        SavedProductClicked: {
            EventAction: 'Saved product clicked in obsess',
            EventLabel: '<Brand name> - <Product name> - <SKU>'
        },
        HotspotAnimationSeen: {
            EventAction: 'Hotspot animation seen',
            EventLabel: '<Store name> - <Scene name>'
        },
        HotspotClickedAfterAnimationSeen: {
            EventAction: 'Hotspot clicked after animation seen',
            EventLabel: '<Store name> - <Scene name>'
        }
    }
}

const Other = {
    EventCategory: 'Other',
    Props: {
        LogoClicked: {
            EventAction: 'Logo clicked',
            EventLabel: 'Logo clicked'
        },
        HowToObsess: {
            EventAction: 'How to obsess',
            EventLabel: 'showed / dismissed'
        },
        Goal: {
            EventAction: 'Goal clicked',
            EventLabel: 'icon / yes / no'
        },
        MenuItemClicked: {
            EventAction: 'Menu item clicked',
            EventLabel: '<Item name>'
        },
        ShareItemClicked: {
            EventAction: 'Share item clicked',
            EventLabel: '<Item name>'
        },
        TalkItemClicked: {
            EventAction: 'Talk item clicked',
            EventLabel: '<Target name>'
        },
        UserCounterClicked: {
            EventAction: 'User Counter clicked',
            EventLabel: 'User Counter clicked'
        },
        FollowItemClicked: {
            EventAction: 'Follow item clicked',
            EventLabel: '<Item name>'
        },
        FollowIGPromptShowed: {
            EventAction: '"Follow us on IG" menu viewed',
            EventLabel: '"Follow us on IG" menu'
        },
        FollowIGPromptClicked: {
            EventAction: '"Follow us on IG" button clicked',
            EventLabel: '"Follow us on IG" menu'
        }
    }
}

const BenchMark = {
    EventCategory: 'BenchMark',
    Props: {
        GPUInfo: {
            EventAction: 'GPU Tracked',
            EventLabel: '<GPU Info>',
            called: false
        }
    }
}

function sendUserAction(eventCategory, eventAction, eventLabel) {
    if (!window.gtag) {
        console.error('no gtag');
        return;
    }
    window.gtag('event', 'play', {
        'event_category': eventCategory,
        'event_action': eventAction,
        'event_label': eventLabel
    });
}

// At Least Once
export function movedOnce() {
    const prop = AtLeastOnce.Props.MovedOnce;
    if (prop.called) {
        return;
    }
    prop.called = true;
    sendUserAction(AtLeastOnce.EventCategory, prop.EventAction, prop.EventLabel);
}

export function productHotspotOnce() {
    const prop = AtLeastOnce.Props.ProductHotspotOnce;
    if (prop.called) {
        return;
    }
    prop.called = true;
    sendUserAction(AtLeastOnce.EventCategory, prop.EventAction, prop.EventLabel);
}

export function buyNowOnce() {
    const prop = AtLeastOnce.Props.BuyNowOnce;
    if (prop.called) {
        return;
    }
    prop.called = true;
    sendUserAction(AtLeastOnce.EventCategory, prop.EventAction, prop.EventLabel);
}

export function interactedOnce() {
    const prop = AtLeastOnce.Props.InteractedOnce;
    if (prop.called) {
        return;
    }
    prop.called = true;
    sendUserAction(AtLeastOnce.EventCategory, prop.EventAction, prop.EventLabel);
}

// Navigation
export function sceneLoaded(sceneName) {
    console.log('sceneloaded', sceneName);
    const prop = Navigation.Props.SceneLoaded;
    sendUserAction(Navigation.EventCategory, prop.EventAction, sceneName);
}

export function navMarkerClicked(destinationSceneName) {
    const prop = Navigation.Props.NavMarkerClicked;
    sendUserAction(Navigation.EventCategory, prop.EventAction, destinationSceneName);
}

export function navMarkerClickedMallHome(targetName) {
    const prop = Navigation.Props.NavMarkerClickedMallHome;
    sendUserAction(Navigation.EventCategory, prop.EventAction, targetName);
}

export function uiStoreClicked(tag, storeName) {
    console.log('ui store clicked: ', tag, storeName);
    const prop = Navigation.Props.UIStoreClicked;
    const eventLabel = [tag, storeName].join(' - ');
    sendUserAction(Navigation.EventCategory, prop.EventAction, eventLabel);
}

export function progressBarClicked(destinationSceneName) {
    const prop = Navigation.Props.ProgressBarClicked;
    sendUserAction(Navigation.EventCategory, prop.EventAction, destinationSceneName);
}

export function progressMessageSeen(sceneName) {
    const prop = Navigation.Props.ProgressMessageSeen;
    sendUserAction(Navigation.EventCategory, prop.EventAction, sceneName);
}

export function NavMarkerClickedAfterProgressMessageSeen(destinationSceneName) {
    const prop = Navigation.Props.NavMarkerClickedAfterProgressMessageSeen;
    sendUserAction(Navigation.EventCategory, prop.EventAction, destinationSceneName);
}

// Product
// * Important: we're putting all product analytics on MallProductModal because only sku is provided on product marker init, we need to fetch productName.
// * Important: a better way would be only supply the sku since product name only helps with making things more clear on GA.
export function productHotspotClicked(storeName, sku, productName) {
    const prop = Product.Props.ProductHotspotClicked;
    const eventLabel = [storeName, productName, sku].join(' - ');
    console.log('Product hotspot clicked:', eventLabel);
    sendUserAction(Product.EventCategory, prop.EventAction, eventLabel);
}

export function buyNowClicked(storeName, sku, productName) {
    const prop = Product.Props.BuyNowClicked;
    const eventLabel = [storeName, productName, sku].join(' - ');
    console.log('Buy now clicked:', eventLabel);
    sendUserAction(Product.EventCategory, prop.EventAction, eventLabel);
}

export function productSaveClicked(storeName, sku, productName) {
    const prop = Product.Props.SaveClicked;
    const eventLabel = [storeName, productName, sku].join(' - ');
    console.log('Product save clicked:', eventLabel);
    sendUserAction(Product.EventCategory, prop.EventAction, eventLabel);
}

export function productUnsaveClicked(storeName, sku, productName) {
    const prop = Product.Props.UnsaveClicked;
    const eventLabel = [storeName, productName, sku].join(' - ');
    console.log('Product unsave clicked:', eventLabel);
    sendUserAction(Product.EventCategory, prop.EventAction, eventLabel);
}

export function productSaveComplete(storeName, sku, productName) {
    const prop = Product.Props.SaveComplete;
    const eventLabel = [storeName, productName, sku].join(' - ');
    console.log('Product save complete:', eventLabel);
    sendUserAction(Product.EventCategory, prop.EventAction, eventLabel);
}

export function productSaveCompleteInObsess(brandName, sku, productName) {
    const prop = Product.Props.SaveComplete;
    const eventLabel = [brandName, productName, sku].join(' - ');
    console.log('Product save complete in obsess:', eventLabel);
    sendUserAction(Product.EventCategory, prop.EventAction, eventLabel);
}

const productZoomedInTracker = new Set();
export function productThumbnailZoomed(storeName, productName, sku) {
    const prop = Product.Props.ProductThumbnailZoomed;
    const eventLabel = [storeName, productName, sku].join('-');
    if (productZoomedInTracker.has(sku)) {
        return;
    };
    productZoomedInTracker.add(sku);
    sendUserAction(Product.EventCategory, prop.EventAction, eventLabel);
}


export function productSaveClickedInObsess(brandName, sku, productName) {
    const prop = Product.Props.SaveClickedInObsess;
    const eventLabel = [brandName, productName, sku].join(' - ');
    console.log('Product save clicked in obsess:', eventLabel);
    sendUserAction(Product.EventCategory, prop.EventAction, eventLabel);
}

export function productUnsaveClickedInObsess(brandName, sku, productName) {
    const prop = Product.Props.UnsaveClickedInObsess;
    const eventLabel = [brandName, productName, sku].join(' - ');
    console.log('Product unsave clicked in obsess:', eventLabel);
    sendUserAction(Product.EventCategory, prop.EventAction, eventLabel);
}

export function userClickedOnSavedProductInObsess(brandName, sku, productName) {
    const prop = Product.Props.SavedProductClicked;
    const eventLabel = [brandName, productName, sku].join(' - ');
    console.log('user clicked on saved product in obsess:', eventLabel);
    sendUserAction(Product.EventCategory, prop.EventAction, eventLabel);
}

let currentScene = '';
export function hotspotAnimationSeen(storeName, sceneName) {
    if (currentScene === sceneName) {
        return;
    }
    const prop = Product.Props.HotspotAnimationSeen;
    const eventLabel = [storeName, sceneName].join(' - ');
    currentScene = sceneName;
    sendUserAction(Product.EventCategory, prop.EventAction, eventLabel);
}

export function hotspotClickedAfterAnimationSeen(storeName, sceneName) {
    const prop = Product.Props.HotspotClickedAfterAnimationSeen;
    const eventLabel = [storeName, sceneName].join(' - ');
    sendUserAction(Product.EventCategory, prop.EventAction, eventLabel);
}

// Other
export function logoClicked() {
    const prop = Other.Props.LogoClicked;
    sendUserAction(Other.EventCategory, prop.EventAction, prop.EventLabel);
}

export function howToObsessShowed() {
    const prop = Other.Props.HowToObsess;
    sendUserAction(Other.EventCategory, prop.EventAction, 'showed');
}

export function howToObsessDismissed() {
    const prop = Other.Props.HowToObsess;
    sendUserAction(Other.EventCategory, prop.EventAction, 'dismissed');
}

export function goalIconClicked() {
    const prop = Other.Props.Goal;
    sendUserAction(Other.EventCategory, prop.EventAction, 'icon');
}

export function goalInfoYesClicked() {
    const prop = Other.Props.Goal;
    sendUserAction(Other.EventCategory, prop.EventAction, 'yes');
}

export function goalInfoNoClicked() {
    const prop = Other.Props.Goal;
    sendUserAction(Other.EventCategory, prop.EventAction, 'no');
}

export function menuItemClicked(itemName) {
    console.log('menu item clicked: ', itemName);
    const prop = Other.Props.MenuItemClicked;
    sendUserAction(Other.EventCategory, prop.EventAction, itemName);
}

export function talkItemClicked(targetName) {
    console.log('talk item clicked: ', targetName);
    const prop = Other.Props.TalkItemClicked;
    sendUserAction(Other.EventCategory, prop.EventAction, targetName)
}

export function shareItemClicked(itemName) {
    console.log('share item clicked: ', itemName);
    const prop = Other.Props.ShareItemClicked;
    sendUserAction(Other.EventCategory, prop.EventAction, itemName);
}

export function userCounterClicked() {
    console.log('user counter clicked');
    const prop = Other.Props.UserCounterClicked;
    sendUserAction(Other.EventCategory, prop.EventAction, prop.EventLabel);
}

export function followItemClicked(itemName) {
    console.log('follow item clicked: ', itemName);
    const prop = Other.Props.FollowItemClicked;
    sendUserAction(Other.EventCategory, prop.EventAction, itemName)
}

export function followIGPromptShowed() {
    const prop = Other.Props.FollowIGPromptShowed;
    sendUserAction(Other.EventCategory, prop.EventAction, prop.EventLabel)
}

export function followIGPromptClicked() {
    const prop = Other.Props.FollowIGPromptClicked;
    sendUserAction(Other.EventCategory, prop.EventAction, prop.EventLabel)
}

// ga Site Speed user timing for how long it takes for the homepage to render
export function homepageRenderedSpeed() {
    window.gtag('event', 'timing_complete', {
        'name': 'homepage-rendered',
        'value': performance.now(),
        'event_category': 'render-speeds'
    });
}

// ga Site Speed user timing
export function storeDirectRenderedSpeed() {
    window.gtag('event', 'timing_complete', {
        'name': 'store-direct-rendered',
        'value': performance.now(),
        'event_category': 'render-speeds'
    });
}

export function storeClickthroughRenderedSpeed() {
    window.gtag('event', 'timing_complete', {
        'name': 'store-clickthrough-rendered',
        'value': performance.now(),
        'event_category': 'render-speeds'
    });
}

// Bench Mark
export function gpuRendererTracked(gpuRenderer) {
    console.log(gpuRenderer);
    const prop = BenchMark.Props.GPUInfo;
    if (prop.called) {
        return;
    }
    prop.called = true;
    sendUserAction(BenchMark.EventCategory, prop.EventAction, gpuRenderer);
}

// Page
export function setupVirtualPageTracking(history) {
    history.listen(location => {
        if (!window.gtag) {
            console.error('no gtag');
            return;
        }
        if (!window.gaId) {
            console.error('no ga id');
            return;
        }

        window.gtag('config', window.gaId, { 'page_path': location.pathname });
    });
}
